<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사업장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            type="search"
            :comboItems="comboItems1"
            itemText="codeName"
            itemValue="code"
            name="inoutType"
            label="입출고 구분"
            v-model="searchParam.inoutType"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            :range="true"
            default="today"
            label="입출고 기간"
            name="inoutDts"
            v-model="searchParam.inoutDts"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-equip
            type="search"
            label="설비"
            name="equipmentCd"
            v-model="searchParam.equipmentCd">
          </c-equip>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="설비/부품 입출고 이력"
      :columns="grid.columns"
      :merge="grid.merge"
      :data="grid.data"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn label="검색" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
      <template v-slot:customArea="{ props, col }">
        <template>
          <span>
            <font v-if="col.name === 'inoutStock' && props.row.inoutType === '입고'" color="blue">
              {{ '+' + props.row[col.name] }}
            </font>
            <font v-else-if="col.name === 'inoutStock' && props.row.inoutType === '출고'" color="red">
              {{ '-' + props.row[col.name] }}
            </font>
            <font v-else>
              {{ props.row[col.name] }}
            </font>
          </span>
        </template>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'equipment-spare-history',
  data() {
    return {
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
      comboItems1: [
        {
          code: '입고',
          codeName: '입고',
        },
        {
          code: '출고',
          codeName: '출고',
        },
      ],
      searchParam: {
        plantCd: null,
        inoutType: null,
        inoutDts: [],
        materialCd: '',
      },
      grid: {
        merge:  [
            { index: 0, colName: "mergeEquipment" },
            { index: 1, colName: "mergeEquipment" },
            { index: 2, colName: "mergeBom" },
            { index: 6, colName: "mergeBom" },
        ],
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            align: 'center',
            style: 'width:200px',
            sortable: false,
          },
          {
            name: 'equipmentName',
            field: 'equipmentName',
            label: '설비명',
            align: 'left',
            style: 'width:250px',
            type: 'link',
            sortable: false,
          },
          {
            name: 'equipmentBomName',
            field: 'equipmentBomName',
            label: '부품명',
            align: 'left',
            sortable: false,
            type: 'link'
          },
          {
            name: 'inoutType',
            field: 'inoutType',
            label: '입/출고 구분',
            align: 'center',
            style: 'width:150px',
            sortable: false,
          },
          {
            name: 'inoutDt',
            field: 'inoutDt',
            label: '입/출고일',
            align: 'center',
            style: 'width:150px',
            sortable: false,
          },
          {
            name: 'inoutStock',
            field: 'inoutStock',
            label: '입/출고량',
            type: 'custom',
            style: 'width:150px',
            sortable: false,
          },
          {
            name: 'realStock',
            field: 'realStock',
            label: '재고량',
            type: 'cost',
            style: 'width:150px',
            sortable: false,
          },
        ],
        data: [],
      },
      editable: true,
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.mdm.equipment.spareParts.list.url;
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    linkClick(row, col){
      if (col.name === 'equipmentBomName') {
        this.popupOptions.title = '설비/부품 입출고 이력 상세';
        this.popupOptions.param = {
          equipmentBomCd: row ? row.equipmentBomCd : '',
        };
        this.popupOptions.target = () => import(`${'./equipmentSpareHistoryDetail.vue'}`);
        this.popupOptions.isFull = false;
      } else {
        this.popupOptions.title = '설비 상세';
        this.popupOptions.param = {
          equipmentCd: row ? row.equipmentCd : '',
          plantCd: row ? row.plantCd : '',
        };
        this.popupOptions.target = () => import(`${'./equipmentDetail.vue'}`);
        this.popupOptions.isFull = true;
      }
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>
